import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {useState} from 'react';
import {FaCloudUploadAlt} from 'react-icons/fa'
import _ from 'lodash';
import update from 'immutability-helper';
import UploadFile from './UploadFile';
import SplitDetailedTable from './SplitDetailedTable';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {redefineDateInTimezone} from '../../helpers/application_helper'
  
function SplitStockOrderModal(props) {

  const initialState = {
    original: props.item,
    revised: {
      order_number: props.item.order_number +"-2",
      order_nickname: props.item.order_nickname,
      stock_order_categories: [],
      start_date: props.item.start_date,
      end_date: props.item.end_date,
      cancellation_date: props.item.cancellation_date,
      early_cancel_date: props.item.early_cancel_date,
      expected_ship_date: props.item.expected_ship_date,
      expected_delivery_date: props.item.expected_delivery_date,
      detailed_entry: props.item.detailed_entry,
    },
  }

  const [state, setState] = useState(initialState);

  const updateQuantities = (old_state) => {
    let categories = [...old_state.stock_order_categories]
    state.total_cost = 0
    state.total_items = 0
    
    if(old_state.detailed_entry){
      categories.map((soc) => {
        let total_cost = 0.0
        let total_qty = 0
        
        if(soc["stock_order_items"]){
          soc.total_qty_by_sizes = {}
          soc["stock_order_items"] && soc["stock_order_items"].map((soi) => {
            if(!soi["deleted"]){
              let total_qty_item = 0
              if(soc.stock_order_sizing_table_id){
                soi["stock_order_quantities"] && soi["stock_order_quantities"].map((soq) => {
                  soq.qty = parseInt(soq.updated_qty) || 0
                  total_qty_item += parseInt(soq.updated_qty) || 0
                  if(!soc.total_qty_by_sizes[soq.size])
                    soc.total_qty_by_sizes[soq.size] = 0
                  soc.total_qty_by_sizes[soq.size] += parseInt(soq.updated_qty) || 0
                })
                soi.updated_qty = total_qty_item
              }
              soi.qty = parseInt(soi.updated_qty) || 0
              
              total_qty += parseInt(soi.qty) || 0
              total_cost += ((parseInt(soi.qty) || 0) * ((parseFloat(soi.cost) || 0.0) + (parseFloat(soi.logo_cost) || 0.0)))
            }})
          soc.qty = total_qty
          soc.total_cost = total_cost
      }
      })
    }
  }

  const handleQuantity = (soc_id, soi_id, size, qty, type) => {
    let stock_order_categories = state[type]["stock_order_categories"]

    let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id)
    let current_qty = 0
    if(soi_id){
      let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
      if(size){
        let soq_index = _.findIndex(stock_order_categories[soc_index].stock_order_items[soi_index].stock_order_quantities, (q) => q.size == size)
        if(soq_index != -1){
          current_qty = stock_order_categories[soc_index].stock_order_items[soi_index].stock_order_quantities[soq_index].updated_qty
          qty = qty < 0 ? 0 : qty > current_qty ? current_qty : qty
          setState(update(state, 
            {
              [type]:
              {
              stock_order_categories: {
                [soc_index] : {
                  stock_order_items : {
                    [soi_index]: {
                      stock_order_quantities : {
                        [soq_index]: 
                        { nr_of_items_to_move: {$set : qty }}
                      }
                    }
                  }
                } 
              }
            }
          }))
        } else {
          setState(update(state, {
            [type]:{
              stock_order_categories: {
                [soc_index] : {
                  stock_order_items : {
                    [soi_index]: {
                      stock_order_quantities : {
                        $push : [{ id: uuidv4(), size: size, qty: qty}]
                      }
                    }
                  }
                } 
              }
            }
          }))
        }
      }
      else
      {
        current_qty = stock_order_categories[soc_index].stock_order_items[soi_index].updated_qty
        qty = qty < 0 ? 0 : qty > current_qty ? current_qty : qty
        setState(update(state, 
          {
            [type]: 
          {
            stock_order_categories: {
              [soc_index] : {
                stock_order_items : {
                  [soi_index]: {
                    nr_of_items_to_move: {$set: qty}
                  }
                }
              }
            }
          }
        }
      ))
      }
    }
    else
    {
      current_qty = stock_order_categories[soc_index].updated_qty
      qty = qty < 0 ? 0 : qty > current_qty ? current_qty : qty
      setState(update(state, {
        [type]: {
          stock_order_categories: {
            [soc_index] : {
              nr_of_items_to_move: {$set: qty}
            }
          }
        }
      }
    ))
    }
  }

  const handleInputChangeDetailed = (soc_id, soi_id, name, value, type) => {
    let stock_order_categories = state[type]["stock_order_categories"]
    let current_qty = 0
    if(soc_id){
      let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id)
      if(soi_id){
        let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
        current_qty = stock_order_categories[soc_index].stock_order_items[soi_index].updated_qty
        value = value < 0 ? 0 : value > current_qty ? current_qty : value
        setState(update(state, {
          [type]: {
          stock_order_categories: {
            [soc_index] : {
              stock_order_items : {
                [soi_index]: {
                  [name] : { $set : value }
                  } 
                }
              } 
            }
          }
          }))
        }
      else
      {
        current_qty = stock_order_categories[soc_index].updated_qty
        value = value < 0 ? 0 : value > current_qty ? current_qty : value
        setState(update(state, {
          [type]: {
          stock_order_categories: {
            [soc_index] : {
              [name] : { $set : value }
              } 
            }
          }
          }))
      }
      }
    else
      setState({...state, [name]: value });
  }

  const fixValue = (event) => {
    let category_id = event.target.getAttribute("category_id")
    let position = event.target.getAttribute("position_type")

    let index_revised = _.findIndex(state.revised.stock_order_categories, function(o) { return (o.category_id == category_id); });
    let index_original = _.findIndex(state.original.stock_order_categories, function(o) { return (o.category_id == category_id); });

    let value = event.target.value
    
    if(!value || isNaN(parseInt(value)) )
      value = ''
    else
      if((parseInt(value) == 0) || (parseInt(value) < 0))
        value = 0
      else
        if(parseInt(value) > parseInt(event.target.max))
          value = event.target.max
        else
          value = parseInt(value)

    if(position == 'original')
      setState(update(state, {
        original: {
          stock_order_categories: {
            [index_original] : {
              ["nr_of_items_to_move"]: { $set : value }
              }
            }
          }
        }))
    else
      setState(update(state, {
        revised: {
          stock_order_categories: {
            [index_revised] : {
              ["nr_of_items_to_move"]: { $set : value }
            }
          }
        }
      }))
  }

  const handleInputChange = (event) => {
    let value = event.target.value
    
    if(event.target.getAttribute("assigned") == "remove_file")
      value = "removed"

    if(event.target.getAttribute("obj_type") == "revised"){

      setState(update(state, {
        revised: {
          [event.target.name] :  { $set : value}
        }
      }))
    }
    else{
      setState(update(state, {
        original: {
          [event.target.name] :  { $set : value}
        }
      }))
    }
  }

  const handleDateChange = (source, attribute, new_date) => {
    //redefineDateInTimezone(new_date, props.state)

    setState({
      ...state,
      [source]: {
        ...(state[source] || {}),
        [attribute]: new_date
      }
    })
  }

  const handleSubmit = (event) => {
    updateQuantities({...state.original})
    updateQuantities({...state.revised})

    let params_revised = {...state.revised}
    let params_original = {...state.original}
    
    if(!params_revised.order_number || !params_original.order_number)
    {
      alert("Order Number is mandatory.");
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    params_revised["files"] = []
    if(params_original.detailed_entry)
    {
      params_revised.stock_order_categories.map((soc) => {
        soc.stock_order_items.map((soi) => { 
          soi.stock_order_quantities = JSON.stringify(soi.stock_order_quantities)
        })
        soc.stock_order_items = JSON.stringify( soc.stock_order_items)
      })

      params_original.stock_order_categories.map((soc) => { 
        soc.stock_order_items.map((soi) => { 
          soi.stock_order_quantities = JSON.stringify(soi.stock_order_quantities)
        })
        soc.stock_order_items = JSON.stringify( soc.stock_order_items)
      })
    }

    params_revised.stock_order_categories = JSON.stringify(params_revised.stock_order_categories)
    params_original.stock_order_categories = JSON.stringify(params_original.stock_order_categories)

    props.item.revised_files.map((element) => {
      params_revised["files"].push(element.id)
    })

    params_revised["files"] = JSON.stringify(params_revised["files"])

    let params = {params_revised: JSON.stringify(params_revised), params_original: JSON.stringify(params_original)}

    props.splitStockOrder(params)

    handleClose()
  }

  const moveActions = (soc_id, action_type, position_type, category_name=null) => {
    let category_id = soc_id
    // let category_name = event.target.getAttribute("category_name")
    // let action_type = event.target.getAttribute("action_type")
    // let position_type = event.target.getAttribute("position_type")
    // let move_id = position_type + "_move_btn_"+ category_id

    // // nr of items to move
    // let input_id = position_type+"_number_of_items_"+category_id

    let index_in = null
    let index_out = null

    if(position_type == "original")
    {
      index_in = _.findIndex(state.revised.stock_order_categories, function(o) { return (o.category_id == soc_id); });
      index_out = _.findIndex(state.original.stock_order_categories, function(o) { return (o.category_id == soc_id); });
    }
    else
    {
      index_out = _.findIndex(state.revised.stock_order_categories, function(o) { return (o.category_id == soc_id); });
      index_in = _.findIndex(state.original.stock_order_categories, function(o) { return (o.category_id == soc_id); });
    }
    
    if(props.item.detailed_entry == true)
    {
      let soc_out = {}
      let soc_in = {}

      if(position_type == "original")
      {
        soc_out = {...state.original.stock_order_categories[index_out]}
        soc_in = {}
        
        if(index_in == -1)
        {
          soc_in = JSON.parse(JSON.stringify(soc_out))
          soc_in.id = uuidv4()
          soc_in.stock_order_items = []
        }
        else
          soc_in = {...state.revised.stock_order_categories[index_in]}
      }
      else
      {
        soc_in = {...state.original.stock_order_categories[index_in]}
        soc_out = {...state.revised.stock_order_categories[index_out]}
      }
      
      soc_out.stock_order_items.map((soi_out) => {
        let soi_in = {}
        let soq_in= {}
        let soi_in_index = -1
        let soq_in_index = -1
        
        if(soc_out.stock_order_sizing_table_id)
        {
          soi_out.stock_order_quantities.map((qt) => 
          {
            if(qt.nr_of_items_to_move > 0)
            {
              qt.updated_qty = parseInt(qt.updated_qty) - parseInt(qt.nr_of_items_to_move)
              
              if(index_in != -1 || (soi_in != {}))
                soi_in_index = _.findIndex(soc_in.stock_order_items, (si) => (si.original_id == soi_out.id) || (si.id == soi_out.original_id))
                if(soi_in_index > -1)
                {
                  soi_in = soc_in.stock_order_items[soi_in_index]
                  soq_in_index = _.findIndex(soc_in.stock_order_items[soi_in_index].stock_order_quantities, (soq) => (soq.original_id == qt.id) || (soq.id == qt.original_id))
                  
                  if(soq_in_index > -1)
                  {
                    soq_in = soc_in.stock_order_items[soi_in_index].stock_order_quantities[soq_in_index]
                    soq_in.updated_qty += parseInt(qt.nr_of_items_to_move)
                  }
                  else
                    {
                      soq_in = {id: uuidv4(), stock_order_item_id: soq_in.id, updated_qty: parseInt(qt.nr_of_items_to_move), nr_of_items_to_move: 0, size: qt.size, original_id: qt.id}
                      soi_in.stock_order_quantities.push(soq_in)
                    }
                
                }
                else
                {
                  soi_in = JSON.parse(JSON.stringify(soi_out))
                  soi_in.id = uuidv4()
                  soi_in.original_id = soi_out.id
                  soi_in.stock_order_category_id = soc_in.id
                  soi_in.qty = null
                  soi_in.updated_qty = 0
                  soi_in.stock_order_quantities = []
                  soi_in.stock_order_quantities.push({id: uuidv4(), stock_order_item_id: soi_in.id, updated_qty: parseInt(qt.nr_of_items_to_move), nr_of_items_to_move: 0, size: qt.size, original_id: qt.id})
                  soc_in.stock_order_items.push(soi_in)
                }
            }
            qt.nr_of_items_to_move = 0
          }
        )
        }
        else
        {
          
          if(index_in != -1)
          soi_in_index = _.findIndex(soc_in.stock_order_items, (si) => (si.original_id == soi_out.id) || (si.id == soi_out.original_id))
            
          if(soi_in_index > -1)
            soi_in = soc_in.stock_order_items[soi_in_index]
          else
          {
            soi_in = JSON.parse(JSON.stringify(soi_out))
            soi_in.id = uuidv4()
            soi_in.original_id = soi_out.id
            soi_in.updated_qty = 0
            soi_in.qty = null
            soi_in.nr_of_items_to_move = 0
            soi_in.stock_order_category_id = soc_in.id
            soi_in.stock_order_quantities = []
          }
          if(soi_out.nr_of_items_to_move > 0)
          {
            soi_out.updated_qty = parseInt(soi_out.updated_qty) - parseInt(soi_out.nr_of_items_to_move)
            soi_in.updated_qty += parseInt(soi_out.nr_of_items_to_move)
            soi_out.nr_of_items_to_move = 0
          
            if(soi_in_index == -1)
              soc_in.stock_order_items.push(soi_in)
          }
        }
      })
      if(position_type == "original")
      {
        if(index_in > -1)
          setState(update(state, {
            original: {
              stock_order_categories: {
                [index_out] : {$set: soc_out}
                }
              },
            revised: {
              stock_order_categories: {
                [index_in] : {$set: soc_in}
              }
            }
          }
          ))
        else
          setState(update(state, {
            original: {
              stock_order_categories: {
                [index_out] : {$set: soc_out}
                }
              },
            revised: {
              stock_order_categories: 
                {$push: [soc_in]}
              }
            }
          ))
      }
      else
      {
        // if(index_in > -1)
        setState(update(state, {
          original: {
            stock_order_categories: {
              [index_in] : {$set: soc_in}
              }
            },
          revised: {
            stock_order_categories: {
              [index_out] : {$set: soc_out}
            }
          }
        }
        ))
        // else
        //   setState(update(state, {
        //     original: {
        //       stock_order_categories: {
        //         [index_original] : {$set: soc}
        //         }
        //       },
        //     revised: {
        //       stock_order_categories: 
        //         {$push: [soc_revised]}
        //       }
        //     }
        //   ))
      }
    }
    else
    {
      let value = 0
      if(position_type == "revised")
        value = state.revised.stock_order_categories[index_in].nr_of_items_to_move
      else
        value = state.original.stock_order_categories[index_out].nr_of_items_to_move
      
      if((value && parseInt(value) != 0) || (action_type == "all")){
        // document.getElementById(input_id).value = 0
        
        let revised_qty = 0
        let revised_cost = 0

        if(index_in >= 0)
        {
        revised_qty = state.revised.stock_order_categories[index_in].qty
        revised_cost = state.revised.stock_order_categories[index_in].total_cost
        }

        let original_qty = state.original.stock_order_categories[index_out].qty
        let original_cost = state.original.stock_order_categories[index_out].total_cost

        let original_up_qty = state.original.stock_order_categories[index_out].updated_qty
        let original_up_cost = state.original.stock_order_categories[index_out].updated_total_cost

        let total_cost = 0
        
        if((parseInt(value) == parseInt(original_up_qty)) && position_type == "original")
          action_type = "all"
        else
          if((parseInt(value) == parseInt(revised_qty)) && position_type == "revised")
            action_type = "all"

        if(action_type == "all"){
          if(position_type == "original")
          {
            value = parseInt(original_up_qty)
            total_cost = parseFloat(original_up_cost)
          }
          else
          {
            value = parseInt(revised_qty)
            total_cost = parseFloat(revised_cost)
          }
        }
        else
          if(position_type == "original")
            total_cost = (parseInt(value) * (parseFloat(original_cost)/parseFloat(original_qty))).toFixed(2)
          else
            total_cost = (parseInt(value) * (parseFloat(revised_cost)/parseFloat(revised_qty))).toFixed(2)
        
        if(position_type == "original")
        { 
          if(index_in >= 0)
            setState(update(state, {
              original: {
                stock_order_categories: {
                  [index_out] : {
                    ["updated_qty"]: { $set : (parseInt(original_up_qty) - parseInt(value)) },
                    ["updated_total_cost"]: { $set : ((parseFloat(original_up_cost) - parseFloat(total_cost)).toFixed(2))},
                    ["nr_of_items_to_move"]: { $set : ''}
                  }
                }
              },
              revised: {
                stock_order_categories: {
                  [index_in] : {
                    ["qty"]: { $set :  (parseInt(revised_qty) + parseInt(value))},
                    ["total_cost"]: { $set : ((parseFloat(revised_cost) + parseFloat(total_cost)).toFixed(2))},
                    ["nr_of_items_to_move"]: { $set : ''}
                  }
                }
              }
            }))
          else
            setState(update(state, {
              original: {
                stock_order_categories: {
                  [index_out] : {
                    ["updated_qty"]: { $set : (parseInt(original_up_qty) - parseInt(value)) },
                    ["updated_total_cost"]: { $set : ((parseFloat(original_up_cost) - parseFloat(total_cost)).toFixed(2))},
                    ["nr_of_items_to_move"]: { $set : ''}
                  }
                }
              },
              revised: {
                stock_order_categories: {
                  $push : [{category_name: category_name,category_id: category_id, qty: value, total_cost: total_cost, nr_of_items_to_move: ''}]
                }
              }
            }))
        }
        else
        {
          setState(update(state, {
            original:{
              stock_order_categories: {
                [index_out] : {
                  ["updated_qty"]: { $set : (parseInt(original_up_qty) + parseInt(value)) },
                  ["updated_total_cost"]: { $set : ((parseFloat(original_up_cost) + parseFloat(total_cost)).toFixed(2))},
                  ["nr_of_items_to_move"]: { $set : ''}
                }
              }
            },
            revised:{
              stock_order_categories: ((parseInt(revised_qty) - parseInt(value)) == 0) ?
                {
                  $splice: [[index_in,1]]
                }
              :
              {
                [index_in] : {
                  ["qty"]: { $set : (parseInt(revised_qty) - parseInt(value)) },
                  ["total_cost"]: { $set : (parseFloat(revised_cost) - parseFloat(total_cost)).toFixed(2)},
                  ["nr_of_items_to_move"]: { $set : ''}
                }
              }
            }
          }))
        }
      }
    }
  }

  const handleClose = () => {
    props.closeSplitModal()
  }
  
  const {modal_split_open} = props;
  const sizing_is_present = _.find(props.item.stock_order_categories, (soc) => soc.stock_order_sizing_table_id)

  return (
    <div className="modal_split_stock_order_container">
      <Modal isOpen={modal_split_open} scrollable={true} detailed_entry={props.item.detailed_entry} sizing_is_present={sizing_is_present}>
        <ModalHeader className="header">
          <h4 className="modal-title form_new_edit m-0">SPLIT STOCK ORDER</h4>
        </ModalHeader>
        <ModalBody className="main">
          <Form>
            <div className="row">
              <div className="col section border-right-gray-dashed">
                <Form.Group as={Row} className="mb-3" controlId="formOriginalStockOrder">
                  <Form.Label column sm="12" className="text-center fs-5">
                    <b>
                      Original Stock Order
                    </b>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formOrderNumberNickname">
                  <Form.Label column sm="2">
                    Order Number
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="text" 
                                  name='order_number'
                                  obj_type = "original"
                                  defaultValue={(state.original.order_number || "")+"-1" }
                                  onChange={handleInputChange} 
                                  />
                  </Col>
                  <Form.Label column sm="2">
                    Order Nickname
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="text" 
                                  name='order_nickname'
                                  obj_type = "original"
                                  value={state.original.order_nickname || ""}
                                  onChange={handleInputChange} 
                                  />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formStartCancelDate">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">
                        Start Ship Date
                      </div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                            key="start_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-start_date`}>
                                This is the earliest date that you will accept the shipment of the order. Confirm with your vendor if unknown.
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Label>
                  <Col sm="4">  
                    <DatePicker className="form-control"
                              name='start_date'
                              id='start_date'
                              obj_type = "original"
                              selected = {Date.parse(moment(state.original.start_date))}
                              value =  {Date.parse(moment(state.original.start_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={(value) => handleDateChange("original", "start_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">
                        End Ship Date
                      </div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                            key="end_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-end_date`}>
                                This is the date that you require that the order is shipped by. If it is not, the order must be cancelled by the vendor. Confirm with your vendor if unknown.
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                    </div>  
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='end_date'
                              id='end_date'
                              obj_type = "original"
                              selected = {Date.parse(moment(state.original.end_date))}
                              value =  {Date.parse(moment(state.original.end_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("original", "end_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formCancelDates">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">Early Cancel Date</div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                            key="early_cancel_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-early_cancel_date`}>
                                This is the date that you must cancel the order by in order to stop shipment and avoid an invoice from the vendor. Confirm with your vendor if unknown.
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='early_cancel_date'
                              id='early_cancel_date'
                              selected = {Date.parse(moment(state.original.early_cancel_date))}
                              value =  {Date.parse(moment(state.original.early_cancel_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("original", "early_cancel_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formStartCancelDate">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-12">Expected Ship Date</div>
                      {/* <div className="col-1 p-0">
                        <OverlayTrigger
                            key="expected_ship_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-expected_ship_date`}>
                                Expected Ship Date
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div> */}
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='expected_ship_date'
                              id='expected_ship_date'
                              obj_type = "original"
                              selected = {Date.parse(moment(state.original.expected_ship_date))}
                              value =  {Date.parse(moment(state.original.expected_ship_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("original", "expected_ship_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-12">Expected Delivery Date</div>
                      {/* <div className="col-1 p-0">
                        <OverlayTrigger
                            key="expected_delivery_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-expected_delivery_date`}>
                                Expected Delivery Date
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div> */}
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='expected_delivery_date'
                              id='expected_delivery_date'
                              obj_type = "original"
                              selected = {Date.parse(moment(state.original.expected_delivery_date))}
                              value =  {Date.parse(moment(state.original.expected_delivery_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("original", "expected_delivery_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
                <div className="row">
                  {props.item.detailed_entry 
                  ?
                  <SplitDetailedTable 
                  stock_order_categories={state.original.stock_order_categories} 
                  sizing_table={props.sizing_table} 
                  handleQuantity={handleQuantity}
                  handleInputChangeDetailed={handleInputChangeDetailed}
                  type="original"
                  moveActions={moveActions}
                  />
                  :
                  <Table responsive style={{minWidth: "600px"}}>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Initial Qty</th>
                        <th>Initial Total Cost</th>
                        <th>Number of Items to Move</th>
                        <th>Current Qty</th>
                        <th>Current Total Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(state.original.stock_order_categories).map((cat, index) => (
                        <tr key={cat.id || Math.random()}>
                          <td>{cat.category_name}</td>
                          <td className="col-1">
                              {cat.qty}
                          </td>
                          <td className="col-1">
                              {cat.total_cost}
                          </td>
                          <td>
                            <div className="row m-0">
                              <a className={((cat.updated_qty == 0) ? 'disable_move_btn' : '') + " col-4 link p-0"} onClick = {(e) => moveActions(cat.category_id, "all", "original", cat.category_name)} category_id={cat.category_id} category_name={cat.category_name} action_type="all" position_type="original">Move All</a>
                              <div className="col-5">
                                <input id = {"original_number_of_items_"+cat.category_id} category_id={cat.category_id} className="w-100 custom_input input_no_spiner"  value={cat.nr_of_items_to_move} position_type="original" style={ {"width": "35px"} } type="" placeholder="0" min='0' max={cat.updated_qty} onChange={fixValue}/>
                              </div>
                              <a className={((cat.nr_of_items_to_move == 0 || cat.nr_of_items_to_move == '') ? 'disable_move_btn' : '') + " link col-3 p-0"} onClick = {(e) => moveActions(cat.category_id, "individual", "original", cat.category_name)} category_id={cat.category_id} category_name={cat.category_name} action_type="individual" position_type="original" id={"original_move_btn_" + cat.category_id}>Move</a>
                            </div>
                          </td>
                          <td id={"original_current_qty_"+cat.category_id} value={cat.qty} className="col-1">
                            {cat.updated_qty}
                          </td>
                          <td id={"original_current_cost_"+cat.category_id} value={cat.total_cost} className="col-1">
                            {cat.updated_total_cost}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  }
                </div>
                <div className="row">
                  <label>Attachments: </label>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>File</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(props.item.files.length > 0) && 
                        props.item.files.map((file) => (
                          <tr key={file.id} className={state.original["remove_file_with_id_"+file.id] ? "d-none" : ""}>
                            <td> 
                              <a className="link" target= '_blank' download={file.name} href={file.url}>{file.name}</a>
                            </td>
                            <td>{file.created_at}</td>
                            <td>
                            <Form.Control as="textarea" rows={3} 
                              name={"file_description_for_"+file.id}
                              defaultValue={file.description || ""}
                              onChange={handleInputChange}/>
                            </td>
                            <td>
                              <a className="link color_red" name={"remove_file_with_id_"+file.id} onClick={handleInputChange} assigned="remove_file">Remove</a>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                  <UploadFile uploadFile={props.uploadFile} customer_id={state.original.customer_id} item_id={state.original.id}/>
                </div>
                <Form.Group className="mb-3" controlId="formVendorInstructions">
                  <Form.Label>Special Instructions to Vendor</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                                name='additional_description_to_vendor'
                                obj_type = "original"
                                value= {state.original.additional_description_to_vendor || ""}
                                onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInternalNotes">
                  <Form.Label>Internal Notes</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                                name='internal_notes'
                                obj_type = "original"
                                value={state.original.internal_notes || ""}
                                onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOrderDescription">
                  <Form.Label>Order Description</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                                name='order_description'
                                obj_type = "original"
                                value={state.original.order_description || ""}
                                onChange={handleInputChange}/>
                </Form.Group>
              </div>
              <div className="col section">
                <Form.Group as={Row} className="mb-3" controlId="formRevisedStockOrder">
                  <Form.Label column sm="12" className="text-center fs-5">
                    <b>
                      New Stock Order
                    </b>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formOrderNumberNickname">
                  <Form.Label column sm="2">
                    Order Number
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="text" 
                                  name='order_number'
                                  obj_type = "revised"
                                  value={state.revised.order_number || ""}
                                  onChange={handleInputChange} 
                                  />
                  </Col>
                  <Form.Label column sm="2">
                    Order Nickname
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="text" 
                                  name='order_nickname'
                                  obj_type = "revised"
                                  value={state.revised.order_nickname || ""}
                                  onChange={handleInputChange} 
                                  />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formStartCancelDate">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">Start Ship Date</div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                            key="start_date2"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-start_date2`}>
                                This is the earliest date that you will accept the shipment of the order. Confirm with your vendor if unknown.
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='start_date'
                              id='start_date'
                              obj_type = "revised"
                              selected = {Date.parse(moment(state.revised.start_date))}
                              value =  {Date.parse(moment(state.revised.start_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("revised", "start_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">End Ship Date</div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                            key="end_date2"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-end_date2`}>
                                This is the date that you require that the order is shipped by. If it is not, the order must be cancelled by the vendor. Confirm with your vendor if unknown.
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='end_date'
                              id='end_date'
                              obj_type = "revised"
                              selected = {Date.parse(moment(state.revised.end_date))}
                              value =  {Date.parse(moment(state.revised.end_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("revised", "end_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formCancelDates">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">Early Cancel Date</div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                            key="early_cancel_date2"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-early_cancel_date2`}>
                                This is the date that you must cancel the order by in order to stop shipment and avoid an invoice from the vendor. Confirm with your vendor if unknown.
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='early_cancel_date'
                              id='early_cancel_date'
                              obj_type = "revised"
                              selected = {Date.parse(moment(state.revised.early_cancel_date))}
                              value =  {Date.parse(moment(state.revised.early_cancel_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("revised", "early_cancel_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formStartCancelDate">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-12">Expected Ship Date</div>
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='expected_ship_date'
                              id='expected_ship_date'
                              obj_type = "revised"
                              selected = {Date.parse(moment(state.revised.expected_ship_date))}
                              value =  {Date.parse(moment(state.revised.expected_ship_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("revised", "expected_ship_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-12">Expected Delivery Date</div>
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='expected_delivery_date'
                              id='expected_delivery_date'
                              obj_type = "revised"
                              selected = {Date.parse(moment(state.revised.expected_delivery_date))}
                              value =  {Date.parse(moment(state.revised.expected_delivery_date))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              onChange={ (value) => handleDateChange("revised", "expected_delivery_date", value)}
                              autoComplete = "off"
                              />
                  </Col>
                </Form.Group>
                <div className="row">
                {props.item.detailed_entry 
                  ?
                  <SplitDetailedTable 
                  stock_order_categories={state.revised.stock_order_categories} 
                  sizing_table={props.sizing_table}
                  handleQuantity={handleQuantity}
                  handleInputChangeDetailed={handleInputChangeDetailed}
                  type="revised"
                  moveActions={moveActions}
                  />
                  :
                  <Table responsive style={{minWidth: "600px"}}>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Qty</th>
                        <th>Total Cost</th>
                        <th><br/>Number of Items<br/> to Move<br/></th>
                      </tr>
                    </thead>
                    <tbody>
                       <React.Fragment>
                        {state.revised.stock_order_categories.map((cat, index) => (
                          <tr key={cat.category_id || Math.random()}>
                            <td>{cat.category_name}</td>
                            <td id={"revised_current_qty_"+cat.category_id} value={cat.qty}>
                                {cat.qty}
                            </td>
                            <td id={"revised_current_cost_"+cat.category_id} value={cat.total_cost}>
                                {cat.total_cost}
                            </td>
                            <td>
                              <div className="row m-0">
                                <a className={"col-5 link p-0"} onClick = {(e) => moveActions(cat.category_id, "all", "revised", cat.category_name)} category_id={cat.category_id} category_name={cat.category_name} action_type="all" position_type="revised">Move All Back</a>
                                <div className="col-3">
                                  <input id = {"revised_number_of_items_"+cat.category_id} className="w-100 custom_input input_no_spiner" value={cat.nr_of_items_to_move} style={ {"width": "35px"}} position_type="revised" category_id={cat.category_id} type="" placeholder="0" min='0' max={cat.qty} onChange={fixValue}/>
                                </div>
                                <a className={((cat.nr_of_items_to_move == 0 || cat.nr_of_items_to_move == '') ? 'disable_move_btn' : '') + " col-4 link p-0"} onClick = {(e) => moveActions(cat.category_id, "individual", "original", cat.category_name)} category_id={cat.category_id} category_name={cat.category_name} action_type="individual" position_type="revised" id={"revised_move_btn_" + cat.category_id}>Move Back</a>
                              </div>
                            </td>
                          </tr>
                        ))}

                        {
                          Array.apply(null, Array(Math.max(state.original.stock_order_categories.length - state.revised.stock_order_categories.length, 0))).map(() =>
                          {
                            return <tr key={Math.random()} style={ {visibility: "hidden" }}>
                                <td><input className="w-100 custom_input input_no_spiner"></input></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                          })
                        }
                      </React.Fragment>
                    </tbody>
                  </Table>
                }
                </div>
                <div className="row">
                  <label>Attachments: </label>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>File</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(props.item.revised_files.length > 0) && 
                        props.item.revised_files.map((file) => (
                          <tr key={file.id} className={state.revised["remove_file_with_id_"+file.id] ? "d-none" : ""}>
                            <td> 
                              <a className="link" target= '_blank' download={file.name} href={file.url}>{file.name}</a>
                            </td>
                            <td>{file.created_at}</td>
                            <td>
                            <Form.Control as="textarea" rows={3} 
                              name={"file_description_for_"+file.id}
                              obj_type = "revised"
                              defaultValue={file.description || ""}
                              onChange={handleInputChange}/>
                            </td>
                            <td>
                              <a className="link color_red" obj_type = "revised" name={"remove_file_with_id_"+file.id} onClick={handleInputChange} assigned="remove_file">Remove</a>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                  <UploadFile uploadFile={props.uploadFile} customer_id={state.original.customer_id} item_id="revised"/>
                </div>
                <Form.Group className="mb-3" controlId="formVendorInstructions">
                  <Form.Label>Special Instructions to Vendor</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                                obj_type = "revised"
                                name='additional_description_to_vendor'
                                value= {state.revised.additional_description_to_vendor || ""}
                                onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInternalNotes">
                  <Form.Label>Internal Notes</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                                name='internal_notes'
                                obj_type = "revised"
                                value={state.revised.internal_notes || ""}
                                onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOrderDescription">
                  <Form.Label>Order Description</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                                name='order_description'
                                obj_type = "revised"
                                value={state.revised.order_description || ""}
                                onChange={handleInputChange}/>
                </Form.Group>
              </div> 
            </div> 
          </Form>
        </ModalBody>
        <ModalFooter className="footer">
        <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn gems_custom_button gems_orange_button float-end"
            onClick={handleSubmit}
          >
            Split
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
} export default SplitStockOrderModal