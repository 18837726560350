import React, {useEffect, useState} from "react";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {validateEmail} from "../../helpers";
import moment from "moment/moment";
import ContactInfoEmail from "./ContactInfoEmail";
import ContactInfoPhone from "./ContactInfoPhone";

const NotificationTableRow = (props) => {
  const {
    customer,
    notification,
    channel,

    validContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
  } = props;

  useEffect(() => {
  }, [props])

  const notificationIsActive = validContactInfo && !notification.is_default_disabled && notification.enabled
  const infoMessage = !validContactInfo  ? `The customer does not have ${channel === 'email' ? 'an email' : 'a cell phone number'} or the ${channel === 'email' ? 'email' : 'cell phone number'} is invalid` : ( notification.is_default_disabled ? notification.info : null)

  return (
    <div id={`${channel}_notification_${notification.type}_${customer.id}`}>
      <div className="m-0 mb-2 row">
        <div className={`col-6 col-lg-9 notification_${channel} p-0 d-flex align-items-center`}>
          <span className="">{notification.text}</span>
        </div>
        <div className="col-6 col-lg-3 p-0  d-flex align-items-center justify-content-end">
          <div className="form-check form-switch me-2 p-0">
            <Form.Check
              type="switch"
              className="p-0"
              id={`email-custom-switch-${customer.id}-${notification.type}`}
              disabled={!validContactInfo || notification.is_default_disabled}
              checked={notificationIsActive}
              onChange={() => changeNotificationSendOption(customer, notification.type, channel, !notificationIsActive)}
            />
          </div>
          {
            infoMessage && <OverlayTrigger
              key={`${channel}_notification_${notification.type}_${customer.id}`}
              placement="top"
              overlay={
                <Tooltip id={`${channel}_notification_${notification.type}_${customer.id}`}>
                  {infoMessage}
                </Tooltip>
              }
            >
              <i className="gga-info-circle add_padding_top_5 me-2 "/>
            </OverlayTrigger>
          }
        </div>
        {notificationIsActive && <div className={'col-12 p-0'}>
          <span className={'link'}
                onClick={() => getOverrideNotification(channel, notification.type)}>
            Edit this specific message
          </span>
        </div>}
      </div>
    </div>
  );
};

export default NotificationTableRow;
