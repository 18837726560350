import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import StockOrdersPageContainer from './StockOrdersPageContainer'

const StockOrdersRoot = (parameters) => {
  const store = configureStore(rootReducer, { stock_order: parameters })

  return (
    <GlobalProvider store={ store }>
      <StockOrdersPageContainer/>
    </GlobalProvider>
  )
}

StockOrdersRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export default StockOrdersRoot
