import { connect } from 'react-redux'
import { closeModal, createStockOrder, updateStockOrder, editStockOrder, uploadFile } from '../actions'
import StockOrderModal from '../components/StockOrderModal'
                     
const mapStateToProps = (state) => ({
  modal_open: state.stock_order.modal_open,
  vendors_list: state.stock_order.vendors_list,
  carrier_options: state.stock_order.carrier_options,
  categories_list: state.stock_order.categories_list,
  categories_attributes: state.stock_order.categories_attributes,
  categories_by_id: state.stock_order.categories_by_id,
  sizing_table: state.stock_order.sizing_table,
  item: state.stock_order.item ,
  title: state.stock_order.title,
  btn_name: state.stock_order.btn_name,
  customer_id: state.stock_order.customer_id,
  po: state.stock_order.po,
  similar_stock_order: state.stock_order.similar_stock_order,
  view_only_stock_orders: state.stock_order.view_only_stock_orders,
  can_place_stock_orders: state.stock_order.can_place_stock_orders,
  default_order_number: state.stock_order.default_order_number,
  optional_fields: state.stock_order.optional_fields,
  date_format: state.stock_order.date_format,
  time_zone: state.stock_order.time_zone,
  stock_order_ai_upload: state.stock_order.stock_order_ai_upload
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  createStockOrder: (data, open_place_popup) => dispatch(createStockOrder(data, open_place_popup)),
  updateStockOrder: data => dispatch(updateStockOrder(data)),
  editStockOrder: id => dispatch(editStockOrder(id)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockOrderModal)
