import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {validateEmail} from "../../helpers";

const ContactInfoEmail = (props) => {
  const {
    customer,
    customer_id,
    validEmail,

    updateContactInfo,
  } = props;

  useEffect(() => {
    contactInfoSetState({...contactInfoState, email: customer.email, edit_email: false})
  }, [props])

  const initialContactInfoState = {
    email: customer.email,
    edit_email: false,
  };

  const [contactInfoState, contactInfoSetState] = useState(initialContactInfoState);

  const customerEmailLink = () => {
    let link_email = null
    if (customer.type === 'member')
      link_email = "/customers/" + customer_id + "/email_center/email_member_page?recipient_member_id=" + customer.id
    if (customer.type === 'guest')
      link_email = "/customers/" + customer_id + "/email_center/create_email_guests"
    return link_email
  }



  const shouldDisplayEmailLink = customerEmailLink()
  const showEditContactIcon = customer.id !== undefined && customer.id !== null && customer.id.trim().length !== 0

  return (
    <tr>
      <td className="width_70">
        <b>Email</b>
      </td>
      <td id="email_info_container">
        {contactInfoState.edit_email ? (
          <div className="row editable_info">
            <div className="col-7">
              <Form.Control
                type="text"
                name="email"
                value={contactInfoState.email || ""}
                onChange={(e) =>
                  contactInfoSetState({...contactInfoState, email: e.target.value})
                }
              />
            </div>
            <div className="col-5 py-1 d-flex align-items-center justify-content-end">
          <span className="btn gems_custom_button gems_orange_button update_contact_info_btn"
            onClick={() => {updateContactInfo(customer, 'email', contactInfoState.email)}}
          >
            Update
          </span>
              <i
                className="fa fa-remove hide_editable_info color_red float-end ms-2 font_size_15 py-1"
                onClick={() =>
                  contactInfoSetState({
                    ...contactInfoState,
                    email: customer.email,
                    edit_email: false,
                  })
                }
              />
            </div>
          </div>
        ) : (
          <div className="row preview_info">
            <div className="col-12">
          <span
            className={`contact_info_email_value ${!validEmail ? "color_red" : ""}`}
            data-email={customer.email}
          >
            {validEmail && shouldDisplayEmailLink ? (
              <a className="link" href={customerEmailLink()} target="_blank">
                {customer.email || "No email"}
              </a>
            ) : (
              customer.email || "No email"
            )}
          </span>
              {showEditContactIcon && (
                <i
                  className="fa fa-pencil cursor_pointer float-end edit_member_guest_email_phone"
                  onClick={() =>
                    contactInfoSetState({
                      ...contactInfoState,
                      edit_email: true,
                      email: customer.email,
                    })
                  }
                />
              )}
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ContactInfoEmail;
