import React from 'react';
import {Col, Form, Spinner, Tab, Table, Tabs} from 'react-bootstrap';
import NotificationsTab from "../../../shared/components/notifications/NotificationsTab";

function Summary(props) {

  const {
    action,
    notification_communication_data,
    activity_logs,
    files,
    customer_id,

    updateContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
  } = props;

  return (<div className="col d-flex flex-column h-100">
    <Tabs
      defaultActiveKey={"notif"}
      transition={false}
      id="summary_tabs"
    >

      {(action === 'lost' || action === 'mark_as_found') && <Tab eventKey="notif" title="Notifications"
                                                         className="h-100 tab-content border-start border-end border-bottom background_white overflow-auto p-2 flex-grow-1">
        <NotificationsTab
          customers_notifications={notification_communication_data}
          customer_id={customer_id}
          updateContactInfo={updateContactInfo}
          changeNotificationSendOption={changeNotificationSendOption}
          getOverrideNotification={getOverrideNotification}
        />
      </Tab>}
      {action !== 'new' && <Tab eventKey="activity_log" title="Activity Log"
                                           className="h-100 tab-content border-start border-end border-bottom background_white overflow-auto p-2 flex-grow-1">
        <Col className="h-100 table-responsive activity_log_content tableFixHead">
          <Table responsive>
            <thead>
            <tr>
              <th>Date / Time</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colSpan={3}>
                <label className='color_red'>Will be implemented in iteration 3</label>
              </td>
            </tr>
            {/*{(activity_logs.length > 0) &&*/}
            {/*  activity_logs.map((log) => (*/}
            {/*    <tr key={log["id"]}>*/}
            {/*      <td>*/}
            {/*        {log["date_time"]}*/}
            {/*      </td>*/}
            {/*      <td dangerouslySetInnerHTML={{__html: log["message"]}}>*/}
            {/*      </td>*/}
            {/*    </tr>*/}
            {/*  ))*/}
            {/*}*/}
            </tbody>
          </Table>
        </Col>
      </Tab>}
    </Tabs>
    <Form.Group className="mb-2 mt-2" controlId="formInternalNotes">
      <Form.Label><b>INTERNAL NOTES:</b></Form.Label>
      <Form.Control as="textarea" rows={3}
                    className={props.isRestricted ? "restricted-container" : ""}
                    name='internal_notes'
                    value={props.state.internal_notes || ""}
                    onChange={(e) => props.handleInputChange("internal_notes", e.target.value)}/>
    </Form.Group>
  </div>)
}

export default Summary