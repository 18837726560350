import {connect} from 'react-redux'
import ListBody from '../components/ListBody'
import {
  editItem,
  openClaimItem,
  openReturnToOwnerItem,
  openMarkAsFoundItem,
  openListViewOptionalFieldsModal,
  transitionAction,
  updateListViewSorting
} from "../actions";

const mapStateToProps = (state) => ({
  in_fields: state.dashboard.settings.in_fields,
  fields_column_mapping: state.dashboard.settings.fields_column_mapping,
  sort_column: state.dashboard.settings.list_sort_column,
  sort_direction: state.dashboard.settings.list_sort_direction,
  items: applyFilters(state.dashboard.items, {
    ...state.dashboard.ui_state.advanced_filter,
    view_filter: state.dashboard.ui_state.view_filter
  }),
})

const mapDispatchToProps = (dispatch) => ({
  openListViewOptionalFieldsModal: () => dispatch(openListViewOptionalFieldsModal()),
  updateListViewSorting: data => dispatch(updateListViewSorting(data)),
  editItem: (id) => dispatch(editItem(id)),
  openClaimItem: (id) => dispatch(openClaimItem(id)),
  openReturnToOwnerItem: (id) => dispatch(openReturnToOwnerItem(id)),
  openMarkAsFoundItem: (id) => dispatch(openMarkAsFoundItem(id)),
  transitionAction: (item_id, transition, params) => dispatch(transitionAction(item_id, transition, params)),
})

export const applyFilters = (items, filters) => {
  return items
    .filter((item) => {
      const {view_filter} = filters;
      let matchesSearch = true
      switch (view_filter) {
        case "LOST_AND_FOUND":
          break;
        case "LOST":
          matchesSearch = ["LOST_CLAIMED", "LOST_CLAIMED_DELETED"].includes(item.status)
          break;
        case "FOUND":
          matchesSearch = ["FOUND_UNCLAIMED", "FOUND_UNCLAIMED_DELETED", "FOUND_CLAIMED", "FOUND_CLAIMED_DELETED", "FOUND_RETURNED_TO_OWNER", "FOUND_RETURNED_TO_OWNER_DELETED"].includes(item.status)
          break;
        default:
          break
      }
      return matchesSearch
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBody)
