import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {validateEmail} from "../../helpers";

const ContactInfoPhone = (props) => {
  const {
    customer,
    customer_id,
    validPhone,

    updateContactInfo,
  } = props;

  useEffect(() => {
    contactInfoSetState({...contactInfoState, phone: customer.phone, edit_phone: false})
  }, [props])

  const initialContactInfoState = {
    phone: customer.phone,
    edit_phone: false,
  };

  const [contactInfoState, contactInfoSetState] = useState(initialContactInfoState);

  const showEditContactIcon = customer.id !== undefined && customer.id !== null && customer.id.trim().length !== 0

  return (
    <tr>
      <td className="width_70">
        <b>Cell Phone</b>
      </td>
      <td id="phone_info_container">
        {contactInfoState.edit_phone ? (
          <div className="row editable_info">
            <div className="col-7">
              <Form.Control
                type="text"
                name="phone"
                value={contactInfoState.phone || ""}
                onChange={(e) =>
                  contactInfoSetState({...contactInfoState, phone: e.target.value})
                }
              />
            </div>
            <div className="col-5 py-1 d-flex align-items-center justify-content-end">
          <span className="btn gems_custom_button gems_orange_button update_contact_info_btn"
                onClick={() => {updateContactInfo(customer, 'phone', contactInfoState.phone)}}
          >
            Update
          </span>
              <i
                className="fa fa-remove hide_editable_info color_red float-end ms-2 font_size_15 py-1"
                onClick={() =>
                  contactInfoSetState({
                    ...contactInfoState,
                    phone: customer.phone,
                    edit_phone: false,
                  })
                }
              />
            </div>
          </div>
        ) : (
          <div className="row preview_info">
            <div className="col-12">
          <span
            className={`contact_info_email_value ${!validPhone ? "color_red" : ""}`}
            data-phone={customer.phone}
          >
            <span className="contact_info_phone_value"
                  data-phone={customer.phone}>{customer.phone || "No cell phone"}</span>
          </span>
              {showEditContactIcon && (
                <i
                  className="fa fa-pencil cursor_pointer float-end edit_member_guest_email_phone"
                  onClick={() =>
                    contactInfoSetState({
                      ...contactInfoState,
                      edit_phone: true,
                      phone: customer.phone,
                    })
                  }
                />
              )}
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ContactInfoPhone;
