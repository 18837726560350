import React, {useState} from 'react';
import {Form, FormControl} from 'react-bootstrap';
import Modal, {ModalBody, ModalFooter, ModalHeader} from "../Modal";
import FroalaEditor from "react-froala-wysiwyg";


function NotificationOverrideModal(props) {


  const {
    customer_id,
    notification_customer_id,
    object_id,
    notif_customer_id,
    channel,
    template_type,
    body,
    modal_is_open,

    closeOverrideNotificationModal,
    updateOverrideNotification,
  } = props;

  const initialState = {
    overwritten_body: body,
  };

  const [state, setState] = useState(initialState);

  const {
    overwritten_body,
  } = state;

  const config_froala = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    key: 'aLF3c1C9C7A5D3F2H3C-7SLJCKHXOSLMc2YGSGe1ZXHSa1CgC3E3G3B2B7A4B3F4E2B2==',
    attribution: false,
    quickInsertButtons: ['image', 'video', 'table', 'ul', 'ol', 'hr'],
    toolbarButtons: {
      'moreRich': {
        'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertFile', 'insertHR'],
      },
      'moreMisc': {},
      'moreText': {

        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']

      },

      'moreParagraph': {

        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']

      }
    },
  }


  const handleClose = () => closeOverrideNotificationModal();

  const handleHtmlChange = (event) => {
    setState({...state, overwritten_body: event });
  }


  const handleSubmit = (e) => {
    updateOverrideNotification(notif_customer_id, channel, template_type, overwritten_body)
  };


  const title = "EDIT NOTIFICATION";
  const submitButtonName = "Save";

  return (<div className="modal_override_notification_container">
    <Modal isOpen={modal_is_open} scrollable={true} className="lostItemModal" style={{width: "1200px", maxWidth: "90%"}}
           dialogClassName="modal-dialog-centered">
      <ModalHeader>
        <div className="row">
          <div className="col">
            <h4 className="modal-title form_new_edit no-margin">
              <span>{title}</span>
            </h4>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {channel === 'email' && <FroalaEditor tag='textarea'
                                              config={config_froala}
                                              model={body}
                                              onModelChange={handleHtmlChange}/>
        }

        {channel === 'sms' && <Form.Control as="textarea"
                                            className={''}
                                            style={{minHeight: "350px"}}
                                            name={"sms_override_notification"}
                                            value={overwritten_body || ""}
                                            onChange={(e) => setState({...state, overwritten_body: e.target.value })}/>
        }

      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`btn gems_custom_button gems_orange_button float-end`}
          onClick={(event) => handleSubmit(event)}
        >
          {submitButtonName}
        </button>
      </ModalFooter>
    </Modal>
  </div>);
}

export default NotificationOverrideModal