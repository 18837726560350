import {connect} from 'react-redux'
import {
  changeNotificationSendOption,
  closeMarkAsFoundItemModal,
  createEmployee,
  createGuest, getOverrideNotification,
  transitionAction, updateContactInfo,
  updateNotifications
} from '../../actions'
import MarkAsFoundItemModal from "../../components/actions_modals/MarkAsFoundItemModal";

const mapStateToProps = (state) => ({
  date_format: state.dashboard.settings.date_format,
  customer_id: state.dashboard.settings.customer_id,
  item: state.dashboard.ui_state.selected_item,

  modal_mark_as_found_item_is_open: state.dashboard.ui_state.modal_mark_as_found_item_is_open,
  notification_communication_data: state.dashboard.ui_state.notification_communication_data,

  members: state.dashboard.cached_member_list,
  employees: state.dashboard.cached_employee_list,
  guests: state.dashboard.cached_guest_list,
  managers: state.dashboard.cached_managers_list,
  current_locations_list: state.dashboard.settings.current_locations,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeMarkAsFoundItemModal()),
  createEmployee: (data, field) => dispatch(createEmployee(data, field)),
  createGuest: data => dispatch(createGuest(data)),
  transitionAction: (item_id, transition, params) => dispatch(transitionAction(item_id, transition, params)),
  updateNotifications: data => dispatch(updateNotifications(data)),
  updateContactInfo: (customer, channel, value) => dispatch(updateContactInfo(customer, channel, value)),
  changeNotificationSendOption: (customer, notification_type, channel, is_enabled) => dispatch(changeNotificationSendOption(customer, notification_type, channel, is_enabled)),
  getOverrideNotification: (item, notif_customer_id, channel, template_type) => dispatch(getOverrideNotification(item, notif_customer_id, channel, template_type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkAsFoundItemModal)
