import React, {useEffect, useState} from "react";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {validateEmail} from "../../helpers";
import moment from "moment/moment";
import ContactInfoEmail from "./ContactInfoEmail";
import ContactInfoPhone from "./ContactInfoPhone";
import NotificationTableRow from "./NotificationTableRow";

const NotificationTable = (props) => {
  const {
    customer,
    notifications,
    customer_id,

    updateContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
  } = props;

  useEffect(() => {
  }, [props])

  const customerEditLink = () => {
    let link_edit = null
    if (customer.type === 'member')
      link_edit = "/customers/" + customer_id + "/members/" + customer.id + "/edit"
    if (customer.type === 'guest')
      link_edit = "/customers/" + customer_id + "/guests?guest_id=" + customer.id
    return link_edit
  }

  const shouldDisplayEditLink = customerEditLink()

  const validEmail = validateEmail(customer.email)
  const validPhone = customer.phone !== undefined && customer.phone !== null && customer.phone.trim().length !== 0


  return (
    <table className="table table-bordered align-middle background_white customer_summary_table">
      <thead></thead>
      <tbody>
      <tr>
        <td className="width_70">
          <b>Name</b>
        </td>
        <td id="name_info_container">
          <span className="contact_info_name_value">
            {shouldDisplayEditLink ? (
              <a className="link" href={shouldDisplayEditLink} target="_blank">
                {customer.name}
              </a>
            ) : (
              customer.name
            )}
          </span>
        </td>
      </tr>

      <ContactInfoEmail
        customer={customer}
        customer_id={customer_id}
        validEmail={validEmail}

        updateContactInfo={updateContactInfo}
      />

      <ContactInfoPhone
        customer={customer}
        customer_id={customer_id}
        validPhone={validPhone}

        updateContactInfo={updateContactInfo}
      />

      <tr className="">
        <td className="width_70">
          <b>Notifications</b>
        </td>
        <td>
          <div className="col-12 container_notifications">
            {notifications.map((notification_config, index) => (<>
              <NotificationTableRow
                customer={customer}
                channel={"email"}
                notification={notification_config.email}
                validContactInfo={validEmail}
                changeNotificationSendOption={changeNotificationSendOption}
                getOverrideNotification={getOverrideNotification}
              />
              <NotificationTableRow
                customer={customer}
                channel={"sms"}
                notification={notification_config.sms}
                validContactInfo={validPhone}
                changeNotificationSendOption={changeNotificationSendOption}
                getOverrideNotification={getOverrideNotification}
              />
            </>))}
          </div>
        </td>
      </tr>
      {customer.type === 'member' && <tr>
        <td colSpan={2}>
          <a className="link ms-1 me-1"
             href={"/customers/" + customer_id + "/members?member_id=" + customer.id}
             target="_blank">
            Click here to edit member's notification
            preferences
          </a>
        </td>
      </tr>}

      {
        customer.type !== 'member' && <tr>
          <td colSpan={2}>
            <div className="guest_notifications_link p-2">
              Notification will be sent based on system wide notification preferences defined
              <a className="link ms-1 me-1" href={"/customers/" + customer_id + "/email_templates"} target="_blank">
                here
              </a>
              for email and
              <a className="link ms-1 me-1" href={"/customers/" + customer_id + "/text_message_templates"}
                 target="_blank">
                here
              </a>
              for text.
            </div>
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
};

export default NotificationTable;
