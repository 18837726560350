import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import NotificationTable from "./NotificationTable";

const NotificationsTab = (props) => {
  const {
    customers_notifications,
    customer_id,

    updateContactInfo,
    changeNotificationSendOption,
    getOverrideNotification,
  } = props;

  return (
    <>
      { customers_notifications.length === 0 &&
        <span>
          There is no customer selected
        </span>
      }
      {
        customers_notifications.map((customer_notifications) =>
          <NotificationTable
            notifications={customer_notifications.notifications}
            customer={customer_notifications.customer}
            customer_id={customer_id}

            updateContactInfo={updateContactInfo}
            changeNotificationSendOption={changeNotificationSendOption}
            getOverrideNotification={getOverrideNotification}
          />
        )
      }
    </>
  );
};

export default NotificationsTab;
