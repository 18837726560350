import { connect } from 'react-redux'
import { closeSplitModal, splitStockOrder, uploadFile} from '../actions'
import SplitStockOrderModal from '../components/SplitStockOrderModal'
                          
const mapStateToProps = (state) => ({
  modal_split_open: state.stock_order.modal_split_open,
  item: state.stock_order.item ,
  sizing_table: state.stock_order.sizing_table,
  date_format: state.stock_order.date_format,
  time_zone: state.stock_order.time_zone
})

const mapDispatchToProps = (dispatch) => ({
  closeSplitModal: () => dispatch(closeSplitModal()),
  splitStockOrder: data => dispatch(splitStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitStockOrderModal)
