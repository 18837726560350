import {connect} from 'react-redux'
import {closeOverrideNotificationModal, updateOverrideNotification} from '../actions'
import NotificationOverrideModal from "../../../shared/components/notifications/NotificationOverrideModal";

const mapStateToProps = (state) => ({
  customer_id: state.dashboard.settings.customer_id,

  object_id: state.dashboard.ui_state.notification_object_id,
  notif_customer_id: state.dashboard.ui_state.notification_customer_id,
  channel: state.dashboard.ui_state.notification_channel,
  template_type: state.dashboard.ui_state.notification_type,
  body: state.dashboard.ui_state.notification_override_body,

  modal_is_open: state.dashboard.ui_state.modal_notification_override,
})

const mapDispatchToProps = (dispatch) => ({
  closeOverrideNotificationModal: () => dispatch(closeOverrideNotificationModal()),
  updateOverrideNotification: (notif_customer_id, channel, template_type, text) => dispatch(updateOverrideNotification(notif_customer_id, channel, template_type, text)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationOverrideModal)
