import {ActionTypes} from '../actions'
import {ViewFilter} from "../components/constants";

const {
  OPEN_LIST_VIEW_ADDITIONAL_FIELDS,
  UPDATE_LIST_VIEW_OPTIONAL_FIELDS,
  CLOSE_OPTIONAL_FIELDS_MODAL,
  UPDATE_LIST_VIEW_SORTING,
  FETCH_LOST_AND_FOUND_DATA_REQUEST,
  CHANGE_VIEW_FILTER,
  NEW_LOST_ITEM,
  NEW_FOUND_ITEM,
  CLOSE_ITEM_MODAL,
  CREATE_ITEM,
  EDIT_ITEM,
  UPDATE_ITEM,
  CREATE_EMPLOYEE,
  CREATE_GUEST,
  TRANSITION,
  OPEN_CLAIM_ITEM_MODAL,
  CLOSE_CLAIM_ITEM_MODAL,
  OPEN_RETURN_TO_OWNER_ITEM_MODAL,
  CLOSE_RETURN_TO_OWNER_ITEM_MODAL,
  OPEN_MARK_AS_FOUND_ITEM_MODAL,
  CLOSE_MARK_AS_FOUND_ITEM_MODAL,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIF_CONTACT_INFO,
  CHANGE_NOTIFICATION_SEND_OPTION,
  OPEN_NOTIFICATION_OVERRIDE_MODAL,
  CLOSE_NOTIFICATION_OVERRIDE_MODAL,
  UPDATE_NOTIFICATION_OVERRIDE,
} = ActionTypes

const initialState = {
  items: [], ui_state: {
    sort_by: [],
    search_term: "",
    advanced_filters: {},
    view_filter: ViewFilter.LOST_AND_FOUND.value,

    selected_item: null,
    manage_item_entry: '',
    notification_communication_data: [],
    notification_override_body: null,
    notification_type: null,
    notification_object_id: null,
    notification_customer_id: null,
    notification_channel: null,


    modal_configure_fields_is_open: false,
    modal_manage_item_is_open: false,
    modal_claim_item_is_open: false,
    modal_return_to_owner_item_is_open: false,
    modal_mark_as_found_item_is_open: false,
    modal_notification_override: false,
  }, app_state: {
    is_loading: false,
  }, settings: {}, cached_member_list: [], cached_employee_list: [], cached_guest_list: [], cached_managers_list: [],
}

const dashboard = (state = initialState, action) => {
  let item = null;
  let items_list = null;
  let dup_notification_communication_data = [];
  let customer_notif = null;
  let dup_notifications = [];
  let dup_notif = null;
  switch (action.type) {
    case FETCH_LOST_AND_FOUND_DATA_REQUEST:
      return {
        ...state, settings: {
          ...state.settings, in_fields: action.data.in_fields, fields_column_mapping: action.data.fields_column_mapping,
        }, items: action.data.items,
      }

    case OPEN_LIST_VIEW_ADDITIONAL_FIELDS:
      return {
        ...state, settings: {
          ...state.settings,
          fields: action.data.fields,
          in_fields: action.data.in_fields,
          mandatory_fields: action.data.mandatory_fields,
        }, ui_state: {
          ...state.ui_state, modal_configure_fields_is_open: true,
        },
      }

    case UPDATE_LIST_VIEW_OPTIONAL_FIELDS:
      return {
        ...state, settings: {
          ...state.settings, fields: JSON.parse(action.data.fields), in_fields: JSON.parse(action.data.in_fields),
        }, ui_state: {
          ...state.ui_state, modal_configure_fields_is_open: false,
        },

      }

    case CLOSE_OPTIONAL_FIELDS_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_configure_fields_is_open: false,
        },
      }

    case UPDATE_LIST_VIEW_SORTING:
      return {
        ...state, list_sort_column: action.data.sort_column, list_sort_direction: action.data.sort_direction
      }

    case CHANGE_VIEW_FILTER:
      return {
        ...state, ui_state: {...state.ui_state, view_filter: action.data.view},
      }

    case NEW_LOST_ITEM:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          manage_item_entry: 'lost',
          selected_item: action.data.item,
          modal_manage_item_is_open: true,
        },
      }

    case NEW_FOUND_ITEM:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          manage_item_entry: 'found',
          selected_item: action.data.item,
          modal_manage_item_is_open: true,
        },
      }

    case CLOSE_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_manage_item_is_open: false,
        },
      }
    case CREATE_ITEM:
      return {
        ...state, items: [...state.items, action.data.item], ui_state: {
          ...state.ui_state, manage_item_entry: null, selected_item: null, modal_manage_item_is_open: false, notification_communication_data: [],
        }, app_state: {
          ...state.app_state,
        },
      }
    case EDIT_ITEM:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          manage_item_entry: 'edit',
          selected_item: action.data.item,
          modal_manage_item_is_open: true,
        },
      }
    case UPDATE_ITEM:
      return {
        ...state,
      }

    case CREATE_EMPLOYEE:
      item = state.ui_state.selected_item
      item[action.data.field] = action.data.employee.id
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item,
        }, cached_employee_list: action.data.employees,
      }

    case CREATE_GUEST:
      item = state.ui_state.selected_item
      item[action.data.field] = action.data.guest.id
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item,
        }, cached_guest_list: action.data.guests,
      }
    case TRANSITION:
      items_list = [...state.items].filter(function (item) {
        return item.id !== action.data.item.id
      })
      items_list.push(action.data.item)
      return {
        ...state, items: items_list, ui_state: {
          ...state.ui_state,
          modal_claim_item_is_open: false,
          modal_return_to_owner_item_is_open: false,
          modal_mark_as_found_item_is_open: false,
          notification_communication_data: [],
        }, app_state: {
          ...state.app_state,
        },
      }

    case OPEN_CLAIM_ITEM_MODAL:
      item = [...state.items].filter(function (item) {
        return item.id === action.data.id
      })[0]
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item, modal_claim_item_is_open: true,
        },
      }
    case CLOSE_CLAIM_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_claim_item_is_open: false,
        }, app_state: {
          ...state.app_state,
        },
      }

    case OPEN_RETURN_TO_OWNER_ITEM_MODAL:
      item = [...state.items].filter(function (item) {
        return item.id === action.data.id
      })[0]
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item, modal_return_to_owner_item_is_open: true,
        },
      }
    case CLOSE_RETURN_TO_OWNER_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_return_to_owner_item_is_open: false,
        },
      }

    case OPEN_MARK_AS_FOUND_ITEM_MODAL:
      item = [...state.items].filter(function (item) {
        return item.id === action.data.id
      })[0]
      return {
        ...state, ui_state: {
          ...state.ui_state, selected_item: item, modal_mark_as_found_item_is_open: true,
        },
      }
    case CLOSE_MARK_AS_FOUND_ITEM_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state, modal_mark_as_found_item_is_open: false,
        },

      }

    case UPDATE_NOTIFICATIONS:
      return {
        ...state, ui_state: {
          ...state.ui_state, notification_communication_data: action.data.notifications,
        },

      }

    case UPDATE_NOTIF_CONTACT_INFO:
      customer_notif = {...[...state.ui_state.notification_communication_data].filter(function (item) {
        return item.customer.id === action.data.customer.id
      })[0]}
      customer_notif.customer = action.data.customer

      dup_notification_communication_data = [...state.ui_state.notification_communication_data].filter(function (item) {
        return item.customer.id !== action.data.customer.id
      })
      dup_notification_communication_data.push(customer_notif)

      return {
        ...state, ui_state: {
          ...state.ui_state, notification_communication_data: dup_notification_communication_data,
        },

      }

    case CHANGE_NOTIFICATION_SEND_OPTION:
      dup_notification_communication_data = [...state.ui_state.notification_communication_data].filter(function (item) {
        return item.customer.id !== action.data.customer.id
      })
      customer_notif = {...[...state.ui_state.notification_communication_data].filter(function (item) {
          return item.customer.id === action.data.customer.id
        })[0]}
      dup_notifications = [...customer_notif.notifications].filter(function (n) {
        return n.email.type !== action.data.notification_type
      })
      dup_notif = customer_notif.notifications.filter(function (n) {
        return n[action.data.channel].type === action.data.notification_type
      })[0]
      dup_notif[action.data.channel].enabled = action.data.is_enabled
      dup_notifications.push(dup_notif)
      customer_notif.notifications = dup_notifications
      dup_notification_communication_data.push(customer_notif)

      return {
        ...state, ui_state: {
          ...state.ui_state, notification_communication_data: dup_notification_communication_data,
        },

      }

    case OPEN_NOTIFICATION_OVERRIDE_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          modal_notification_override: true,
          notification_override_body: action.data.body,
          notification_type: action.data.notification_type,
          notification_object_id: action.data.notification_object_id,
          notification_customer_id: action.data.notification_customer_id,
          notification_channel: action.data.notification_channel,
        },

      }
    case CLOSE_NOTIFICATION_OVERRIDE_MODAL:
      return {
        ...state, ui_state: {
          ...state.ui_state,
          modal_notification_override: false,
          notification_override_body: null,
          notification_type: null,
          notification_object_id: null,
          notification_customer_id: null,
          notification_channel: null,
        },

      }

    case UPDATE_NOTIFICATION_OVERRIDE:
      dup_notification_communication_data = [...state.ui_state.notification_communication_data].filter(function (item) {
        return item.customer.id !== action.data.notification_customer_id
      })
      customer_notif = {...[...state.ui_state.notification_communication_data].filter(function (item) {
          return item.customer.id === action.data.notification_customer_id
        })[0]}
      dup_notifications = [...customer_notif.notifications].filter(function (n) {
        return n.email.type !== action.data.notification_type
      })
      dup_notif = customer_notif.notifications.filter(function (n) {
        return n[action.data.channel].type === action.data.notification_type
      })[0]
      dup_notif[action.data.channel].is_overwritten = true
      dup_notif[action.data.channel].overwritten_body = action.data.body
      dup_notifications.push(dup_notif)
      customer_notif.notifications = dup_notifications
      dup_notification_communication_data.push(customer_notif)

      return {
        ...state, ui_state: {
          ...state.ui_state, notification_communication_data: dup_notification_communication_data,
        },

      }

    default:
      return {
        ...initialState, ...state,
      }
  }
}

export default dashboard