import React from 'react'
import {ActionsConfig, DropdownActionsByStatus} from "./constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function ItemDropdown(props) {
  const {
    item,

    editItem,
    openClaimItem,
    openReturnToOwnerItem,
    openMarkAsFoundItem,
    transitionAction,
  } = props


  const getMethodByAction = (action, event) => {
    switch (action) {
      case "edit":
        editItem(event.currentTarget.id)
        break;
      case "mark_as_found":
        openMarkAsFoundItem(event.currentTarget.id)
        break;
      case "claim_item":
        openClaimItem(event.currentTarget.id)
        break;
      case "return_to_owner":
        if (item.status === 'FOUND_CLAIMED')
          transitionAction(item.id, 'return_to_owner', {})
        else
          openReturnToOwnerItem(event.currentTarget.id)
        break;
      case "revert_to_previous_state":
        transitionAction(item.id, 'revert_to_previous_state', {})
        break;
      case "remove_item":
        transitionAction(item.id, 'remove_item', {})
        break;
      case "restore_item":
        transitionAction(item.id, 'restore_item', {})
        break;
      default:
        console.log("Unknown action");
    }
  }


  const get_action_markup = (action, item) => {
    // Default to an unknown action
    const actionDetails = ActionsConfig[action] || {
      label: 'UNKNOWN', icon: 'fa fa-forward', colorClass: 'color_black',
    };

    return (<a className={`${actionDetails.colorClass} float-end cursor_pointer gap-5px d-flex align-items-center ${action === 'send_email' ? 'color_gray disabled cursor-not-allowed' : ''}`}
               onClick={(event) => getMethodByAction(action, event)} id={item.id}>
      <i className={`${actionDetails.icon}`}/>
      <span className="">
        {actionDetails.label}
        {
          action === 'send_email' && <OverlayTrigger
            key={`send_email_${item.id}`}
            placement="top"
            overlay={
              <Tooltip id={`send_email_${item.id}`}>
                Not implemented yet
              </Tooltip>
            }
          >
            <i className="gga-info-circle add_padding_top_5 ms-2 color_red"/>
          </OverlayTrigger>
        }
      </span>


    </a>);
  };
  return (<td className="text_align_center background_white ps-4">
    <div className="dropdown">
      <button className="btn dropdown-toggle lh-1 fs-5 bg-white" style={{
        paddingBottom: "0px",
        paddingTop: "0px",
        paddingLeft: "3px",
        paddingRight: "3px",
        border: '1px solid rgb(176,177,180)'
      }} aria-expanded="false" data-bs-toggle="dropdown" type="button"
              id={"dropdownContainerItemMenu_" + item.id}/>
      <ul className="dropdown-menu" aria-labelledby={"dropdownContainerItemMenu_" + item.id}
          style={{minWidth: '250px'}}>
        {(DropdownActionsByStatus[item.status] || []).map((action, index) => <li key={index} className="dropdown-item">
          {get_action_markup(action, item)}
        </li>)}

      </ul>
    </div>
  </td>)
}

export default ItemDropdown
