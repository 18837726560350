import {connect} from 'react-redux'
import SettingsPage from "../components/SettingsPage";
import {
  cancelCategory,
  cancelLocation,
  changeCategoryField,
  changeLocationField,
  createCategory, createLocation,
  editCategory,
  editLocation,
  newCategory,
  newLocation,
  removeCategory, removeLocation,
  saveCategory, saveGeneralSettings, saveLocation, updateLocationDefaultOption
} from "../actions";

const mapStateToProps = (state) => ({
  lfin_from: state.settings.general.lfin_from,
  lfin_prefix: state.settings.general.lfin_prefix,
  current_locations: state.settings.current_locations.sort((a, b) => compareObjects(a, b)),
  categories: state.settings.categories.sort((a, b) => compareObjects(a, b)),
})

const mapDispatchToProps = (dispatch) => ({
  saveGeneralSettings: (data) => dispatch(saveGeneralSettings(data)),

  newLocation: () => dispatch(newLocation()),
  editLocation: (location) => dispatch(editLocation(location)),
  saveLocation: (location) => dispatch(saveLocation(location)),
  createLocation: (location) => dispatch(createLocation(location)),
  changeLocation: (location, field, value) => dispatch(changeLocationField(location, field, value)),
  cancelLocation: (location) => dispatch(cancelLocation(location)),
  removeLocation: (location) => dispatch(removeLocation(location)),
  updateDefaultOption: (location_id, enabled) => dispatch(updateLocationDefaultOption(location_id, enabled)),

  newCategory: () => dispatch(newCategory()),
  editCategory: (category) => dispatch(editCategory(category)),
  saveCategory: (category) => dispatch(saveCategory(category)),
  createCategory: (category) => dispatch(createCategory(category)),
  changeCategory: (category, field, value) => dispatch(changeCategoryField(category, field, value)),
  cancelCategory: (category) => dispatch(cancelCategory(category)),
  removeCategory: (category) => dispatch(removeCategory(category)),
})


const compareObjects = (a, b) => {
  if (a.id == null || b.id == null)
    return 0
  a.name.localeCompare(b.label, undefined, { sensitivity: "base" })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage)
